<template>
  <EntityList
    :config="config"
    :columns="columns"
    :formatted-columns="formattedColumns"
    :filters="filters"
    :filter-vals.sync="filterVals"
    :extra-actions="extraActions"
    :allow-duplicate="false"
    :has-insert-btn="false"
    :custom-query="customQuery"
    authority="authority"
    single-edit
    inline-actions
    hide-edit
    hide-delete
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'

import storeModule from '../partnerProductsStoreModule'
import storeModule1 from '../partnerStoreModule'

import { isAdmin, isSupplier } from '@/auth/utils'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
      sellerOptions: [],
      categoriesOptions: [],
      countryOptions: [],
      coverAreaOptions: [],
    }
  },
  computed: {
    isAdmin() {
      return isAdmin()
    },
    isSupplier() {
      return isSupplier()
    },
    config() {
      if (this.isAdmin) {
        return {
          store: storeModule,
          module: 'products/partners',
          endpoint: 'products/partners',
          route: 'products/partners',
          title: {
            single: this.$i18n.t('Product'),
            plural: this.$i18n.t('Products'),
          },
        }
      }
      //to list active sellers as companies under Active Partnerships tab
      else if (this.isSupplier) {
        return {
          store: storeModule1,
          module: 'partner-requests/active-partners',
          endpoint: 'partner-requests/active-partners',
          actionsEndpoint: 'partner-requests/update-status',
          route: 'partner-requests',
          title: {
            single: this.$i18n.t('Seller'),
            plural: this.$i18n.t('Sellers'),
          },
        }
      }
       else {
        // to list active suppliers as companies under My Suppliers subcategory
        return {
          store: storeModule1,
          module: 'partner-requests/active-partners',
          endpoint: 'partner-requests/active-partners',
          actionsEndpoint: 'partner-requests/update-status',
          route: 'partner-requests',
          title: {
            single: this.$i18n.t('Supplier'),
            plural: this.$i18n.t('Suppliers'),
          },
        }
      }
    },
    authority(){
      if(this.isAdmin){
        return("Products_Product")
      }
      else{
        return("Users_Partner_Connect_Edit")
      }
    },
    customQuery() {
      if (this.isAdmin) {
        return ({ selleronly: true,})
      } else {
        return null
      }
    },

    columns() {
      if (this.isAdmin) {
        return [
          { key: 'id', label: '#', sortable: true },
          { key: 'featuredImg', label: this.$i18n.t('photo'), sortable: false },
          { key: 'published', label: '' },
          { key: 'title', label: this.$i18n.t('name'), sortable: true },
          {
            key: 'seller.title',
            label: this.$i18n.t('seller'),
            sortable: false,
          },
          { key: 'code', label: this.$i18n.t('code'), sortable: true },
          { key: 'regularPrice', label: this.$i18n.t('price'), sortable: true },
          { key: 'actions', label: this.$i18n.t('actions') },
        ]
      } else {
        return [
          { key: 'id', label: '#', sortable: true },
          { key: 'logo', label: this.$i18n.t('logo'), sortable: false },
          { key: 'title', label: this.$i18n.t('name'), sortable: true },
          {
            key: 'companyEmail',
            label: this.$i18n.t('email'),
            sortable: false,
          },
          { key: 'actions', label: this.$i18n.t('actions') },
        ]
      }
    },
    formattedColumns() {
      if (this.isAdmin) {
        return [
          { key: 'published', type: 'status' },
          { key: 'featuredImg', type: 'image', width: 80 },
        ]
      } else {
        return [{ key: 'logo', type: 'logo' }]
      }
    },
    extraActions() {
      if (this.isAdmin) {
        return [
          {
            icon: 'EyeIcon',
            title: 'View in e-shop',
            // eslint-disable-next-line prefer-template
            href: process.env.VUE_APP_SHOP_URL + 'product/',
            appendField: 'eshopSlug',
            variant: 'outline-secondary',
            renderCondition: {
              key: 'published',
              value: true,
            },
          },
        ]
      } else if(this.isSupplier) {
        return [
          {
            icon: 'EyeIcon',
            title: 'View in e-shop',
            // eslint-disable-next-line prefer-template
            href: process.env.VUE_APP_SHOP_URL + 'seller/',
            appendField: 'eshopSlug',
            variant: 'outline-secondary',
            renderCondition: {
              key: 'published',
              value: true,
            },
          },
          {
            callback: 'updatePartnerStatus_DELETED',
            icon: 'Trash2Icon',
            title: 'Terminate',
            variant: 'danger',
            // renderCondition: {
            //   key: 'published',
            //   value: false,
            // },
          },
        ]
      }else {
        return [
          {
            icon: 'EyeIcon',
            title: 'View in e-shop',
            // eslint-disable-next-line prefer-template
            href: process.env.VUE_APP_SHOP_URL + 'supplier/',
            appendField: 'eshopSlug',
            variant: 'outline-secondary',
            renderCondition: {
              key: 'published',
              value: true,
            },
          },
          {
            callback: 'updatePartnerStatus_DELETED',
            icon: 'Trash2Icon',
            title: 'Terminate',
            variant: 'danger',
            // renderCondition: {
            //   key: 'published',
            //   value: false,
            // },
          },
        ]
      }
    },

    filters() {
      if (this.isAdmin) {
        return [
          {
            name: 'seller',
            label: this.$t('seller'),
            options: this.sellerOptions,
          },
          {
            name: 'category',
            label: this.$t('category'),
            options: this.categoriesOptions,
          },
          {
            name: 'createdfrom',
            type: 'date',
            label: this.$i18n.t('Created From'),
            value: null,
            maxDateFieldIdx: '3',
            maxDate: new Date(),
          },
          {
            name: 'createdto',
            type: 'date',
            label: this.$i18n.t('Created To'),
            value: null,
            minDateFieldIdx: '2',
            maxDate: new Date(),
          },
        ]
      } else {
        return [
          {
            name: 'country',
            label: this.$t('country'),
            options: this.countryOptions,
          },
          {
            name: 'state',
            label: this.$t('State'),
            options: this.coverAreaOptions,
          },
        ]
      }    
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchSellers').then((response) => {
      this.sellerOptions = response.data
    })
    this.$store.dispatch('app-common/fetchCategories').then((response) => {
      this.categoriesOptions = response.data
    })
    this.$store.dispatch('app-common/fetchCountries').then((response) => {
      this.countryOptions = response.data
    })
    this.$store.dispatch('app-common/fetchCoverAreas').then((response) => {
      this.coverAreaOptions = response.data
    })

  },
}
</script>
